/*
    ---- Category Definition ----
 */
module.exports = {
    category: {
        app: 'app',
        app_nested: 'app_nested',
        infection: 'infection',
        ssl: 'ssl',
        ssl_nested: 'ssl_nested',
        network: 'network',
        dns: 'dns',
        breach: 'breach',
        web: 'web',
        dsgvo: 'dsgvo',
        dsgvo_nested: 'dsgvo_nested',
        nessus_nested: 'nessus_nested',
        nessus: 'nessus',
        greenbone_nested: 'greenbone_nested',
        greenbone: 'greenbone',
        csv: 'csv',
        pingcastle_nested: 'pingcastle_nested',
        pingcastle: 'pingcastle',
        ddos: 'ddos',
        ddos_nested: 'ddos_nested'
    },
    subCategory: {
        ssl_nested: {
            expiry: 'expiry',
            ciphers: 'oldCiphers',
            invalid: 'invalid',
            aggregated: 'aggregated'
        },
        app_nested: {
            cve: 'cve',
            uptodate: 'uptodate'
        },
        web: {
            certificates: 'certificates',
            missingHeaders: 'missingHeaders'
        },
        dsgvo_nested: {
            cookie: 'cookie',
            script: 'script'
        },
        nessus_nested: {
            nessus: 'nessus'
        },
        greenbone_nested: {
            greenbone: 'greenbone'
        },
        pingcastle_nested: {
            pingcastle: 'pingcastle'
        },
        ddos_nested: {
            ddos: 'ddos'
        }
    },
    itemId: {
        breach: 'breach.name', //"Datenpanne",////
        ssl_nested: {
            aggregated: 'misconfiguration', //"Fehlkonfiguration",
            ciphers: 'ciphers', //"Verschlüsselungsverfahren",
            expiry: 'validityperiod', //"Gültigkeitsdauer",
            invalid: 'validation' //"Validierbarkeit"
        },
        app_nested: {
            app: 'categories.app',
            os: 'os'
        },
        network: {
            rpc: '111/tcp_rpcbind'
        },
        web: {
            certificates: 'configuration', //"Konfiguration",//"web.certificates",
            missingHeaders: 'httpheader', //"Konfiguration",//"web.certificates",
            git: 'git', //"web.git",
            svn: 'svn', //"web.svn"
            trace: 'trace', //"web.svn"
            env: 'env',
            httpOnly: 'httponly',

            passwordField: 'passwordfield',

            error: 'error',

            sqlError: 'sqlerror',
            directoryListing: 'directorylisting',
            btcAddress: 'btcaddress',
            pathLeak: 'pathleak',
            genError: 'generror',
            codeLeak: 'codeleak',

            webSpeed: 'webspeed',
            bigSize: 'bigsize',
            highTTFB: 'highttfb'
        },
        ssl: 'misconfiguration', //"Fehlkonfiguration",//"ssl",//
        dsgvo_nested: {
            cookie: 'dsgvowarning', //"DSGVO-Warnung",//"dsgvo_nested.cookie",//
            script: 'dsgvowarning', //"DSGVO-Warnung"//"dsgvo_nested.script"//
            googleResource: 'google_resource',
            serverLocation: 'serverlocation'
        },
        dsgvo: 'serverlocation', //"Serverstandort",
        infection: 'infection.name', //"Infektion",//"infection",//
        dns: {
            spfSub: 'spfSub', //"dns.spfSub",//
            daneHttps: 'daneHttps', //"dns.daneHttps",//
            daneSmtp: 'daneSmtp', //"dns.daneSmtp",//
            dmarc: 'dmarc', //"dns.dmarc",//
            validDmarc: 'validDmarc', //"dns.validDmarc",//
            spf: 'spf',
            soa: 'soa',
            caa: 'caa',
            zonetransfer: 'zonetransfer',
            openresolvers: 'openresolvers',
            dnssec: 'dnssec',
            mx: 'mx',
            mxfalse: 'mxfalse',
            whois: 'whois',
            rareNS: 'rareNS'
        },
        nessus: {},
        csv: {},
        ddos: {
            multipleARecords: 'multipleARecords',
            cdn: 'cdn',
            nsEntries: 'nsEntries',
            mxEntries: 'mxEntries',
            multipleNsIp: 'multipleNsIp',
            multipleNsLocation: 'multipleNsLocation',
            multipleNsHoster: 'multipleNsHoster',
            multipleMxIp: 'multipleMxIp',
            multipleMxLocation: 'multipleMxLocation',
            multipleMxHoster: 'multipleMxHoster',
            ttfb: 'ttfb',
            ttfbEntry: 'ttfbEntry',
            webSize: 'webSize',
            webSizeEntry: 'webSizeEntry',
            webSpeed: 'webSpeed',
            webSpeedEntry: 'webSpeedEntry'
        }
    },
    itemTitles: {
        app: {
            single: 'app_titles.single',
            multiple: 'app_titles.multiple',
            singleDate: 'app_titles.singledate',
            multipleDate: 'app_titles.multipledate'
        },
        app_nested: {
            app: 'app_titles.app_nested',
            os: 'os_nested_titles',
            affectedMultiple: 'app_titles.app_nested_affected_multiple',
            affectedSingle: 'app_titles.app_nested_affected_single',
            fpMultiple: 'app_titles.app_nested_fp_multiple',
            fpSingle: 'app_titles.app_nested_fp_single'
        },
        breach: 'breach_titles.accounts', //"Es sind Accounts von Datenpannen betroffen",
        ssl_nested: {
            general: 'ssl_titles.',
            aggregated: 'ssl_titles.aggregated', //"Fehlkonfigurierte Zertifikatsparameter",
            ciphers: 'ssl_titles.ciphers', //"Veraltete oder unsichere Verschlüsselungsverfahren",
            expiry: 'ssl_titles.expiry', //"Ablaufende oder abgelaufene Zertifikate",
            invalid: 'ssl_titles.invalid' //"Nicht validierbare Zertifikate oder fehlerhafte Validierungspfade"
        },
        web: {
            certificates: 'web_titles.certificates', //"Unsichere Konfiguration von Web-Anwendungen entdeckt",
            missingHeaders: 'web_titles.missing_headers', // das hauptitem mit allen headers
            missingHeader: 'web_titles.missing_header', // ein konkreter header
            git: 'web_titles.git',
            svn: 'web_titles.svn',
            trace: 'web_titles.trace',
            env: 'web_titles.env',
            httpOnly: 'web_titles.httponly',
            passwordField: 'web_titles.passwordfield',

            error: 'web_titles.error',

            sqlError: 'web_titles.sqlerror',
            directoryListing: 'web_titles.directorylisting',
            btcAddress: 'web_titles.btcaddress',
            pathLeak: 'web_titles.pathleak',
            genError: 'web_titles.generror',
            codeLeak: 'web_titles.codeleak',

            webSpeed: 'web_titles.webspeed',
            highTTFB: 'web_titles.highttfb',
            bigSize: 'web_titles.bigsize'
        },
        dsgvo_nested: {
            cookie: 'cookieconsent', //"Cookies vor Zustimmung gesetzt",

            scriptNamed: 'dsgvo_titles.dsgvo_nested_script_named',
            scriptUnNamed: 'dsgvo_titles.dsgvo_nested_script_unnamed',
            cookieNamed: 'dsgvo_titles.dsgvo_nested_cookie_named', //      "cookie_named",
            cookieUnnamed: 'dsgvo_titles.dsgvo_nested_cookie_unnamed', //      "cookie_unnamed",

            googleResource: 'dsgvo_titles.dsgvo_nested_google_resource',

            script: 'thirdpartyscripts' //"Drittanbieter-Tracking-Skripte ohne Zustimmung eingebunden"
        },
        dsgvo: 'servernoteu',
        infection: 'maliciousactions', //"Es wurden schadhafte Aktionen aus dem Firmennetzwerk beobachtet",
        torexit: 'torexitnodes', //"",
        dns: {
            spfSub: 'dns_titles.spfSub', //"Subdomain-SPF-Konfiguration",
            daneHttps: 'dns_titles.daneHttps', //"DANE-HTTPS-Konfiguration ",
            daneSmtp: 'dns_titles.daneSmtp', //"DANE-SMTP-Konfiguration",
            dmarc: 'dns_titles.dmarc', //"DMARC-Konfiguration",
            dmarcSub: 'dns_titles.dmarcSub', //"DMARC-Konfiguration",
            validDmarc: 'dns_titles.validDmarc', //"DMARC-Konfiguration",
            spf: 'dns_titles.spf', //"SPF-Konfiguration"
            caa: 'dns_titles.caa',
            soa: 'dns_titles.soa',
            zonetransfer: 'dns_titles.zonetransfer',
            openresolvers: 'dns_titles.openresolvers',
            dnssec: 'dns_titles.dnssec',
            mx: 'dns_titles.mx',
            mxfalse: 'dns_titles.mxfalse',
            whois: 'dns_titles.whois',
            rareNS: 'dns_titles.rareNS'
        },
        network: {
            nmap: 'network_titles.accessible_nmap',
            general: 'network_titles.',
            ports: 'network_titles.ports.',
            services: 'network_titles.services.',
            default: 'default'
        },
        ddos: {
            multipleARecords: 'ddos_titles.multiple_a_records.header',
            cdn: 'ddos_titles.cdn.header',
            nsEntries: 'ddos_titles.ns_entries.header',
            mxEntries: 'ddos_titles.mx_entries.header',
            multipleNsIp: 'ddos_titles.multiple_ns_ip.header',
            multipleNsLocation: 'ddos_titles.multiple_ns_location.header',
            multipleNsHoster: 'ddos_titles.multiple_ns_hoster.header',
            multipleMxIp: 'ddos_titles.multiple_mx_ip.header',
            multipleMxLocation: 'ddos_titles.multiple_mx_location.header',
            multipleMxHoster: 'ddos_titles.multiple_mx_hoster.header',
            ttfb: 'ddos_titles.ttfb.header',
            ttfbEntry: 'ddos_titles.ttfb.entry_header',
            webSize: 'ddos_titles.web_size.header',
            webSizeEntry: 'ddos_titles.web_size_entry.entry_header',
            webSpeed: 'ddos_titles.web_speed.header',
            webSpeedEntry: 'ddos_titles.web_speed.entry_header'
        }
    }
}
