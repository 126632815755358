import {api} from '@/new/helper/Http'

import {filterForLatestVersion} from '../../../models/shared/questionnaire'

export default {
    // Questionnaires

    async getQuestionnairesAndAnswerings() {
        return api.get(`/products/questionnaires/overview`)
    },

    async createQuestionnaire({name, companyId, referenceId}) {
        return api.post(`/products/questionnaires`, {name, companyId, referenceId})
    },

    async getQuestionnaire(questionnaireId) {
        const id = questionnaireId ? questionnaireId : ''
        return api.get(`/products/questionnaires/${id}`)
    },

    async getQuestionnaireByCategory(productId) {
        const response = await api.get(`/products/questionnaires/${productId}/by-category`)

        const mappedResult = {}
        for (const x of response.data) {
            ;(mappedResult[x.CategoryId] = mappedResult[x.CategoryId] || []).push(x.QuestionnaireId)
        }

        response.data = mappedResult

        return response
    },

    async updateQuestionnaire(questionnaire) {
        // use deep copy to make sure that we do not save too much in DB
        const questionnaireCopy = JSON.parse(JSON.stringify(questionnaire))
        for (const c of questionnaireCopy.structure.categories) {
            delete c.questions // only keep ids
            delete c.expanded // helper field
        }

        return api.put(`/products/questionnaires/${questionnaire.id}`, questionnaireCopy)
    },

    async deleteQuestionnaire(questionnaireId) {
        return api.delete(`/products/questionnaires/${questionnaireId}`)
    },

    async translateQuestionnaire(questionnaireId) {
        return api.post(`/products/questionnaires/${questionnaireId}/translate`)
    },

    async notifySuppliers({questionnaireId, answeringIds}) {
        return api.post(`/products/questionnaires/${questionnaireId}/notify`, {answeringIds})
    },

    async exportQuestionnaire(questionnaireId) {
        return api.get(`/products/questionnaires/${questionnaireId}/export`)
    },

    async importQuestionnaire({companyId, json}) {
        return api.post(`/products/questionnaires/import`, {companyId, json})
    },

    async getStandardQuestionnaires(questionnaireId) {
        return api.get(`/products/questionnaires/${questionnaireId}/standard`)
    },

    async getStandardQuestionnairesByCompanyId(companyId) {
        return api.get(`/products/questionnaires/standard-by-company/${companyId}`)
    },

    async listQuestionnairesByTaskId(taskId) {
        return api.get(`/products/questionnaires/list-by-task/${taskId}`)
    },

    // Questions
    async getQuestions(questionnaireId) {
        const response = await api.get(`/products/questionnaires/${questionnaireId}/questions`)
        response.data = filterForLatestVersion(response.data)
        return response
    },

    async createQuestion({questionnaireId, question}) {
        return api.post(`/products/questionnaires/${questionnaireId}/questions`, question)
    },

    async linkQuestions({questionnaireId, questionIds}) {
        return api.post(`/products/questionnaires/${questionnaireId}/questions/link`, {questionIds})
    },

    async updateQuestion(question) {
        return api.put(`/products/questionnaires/questions/${question.id}`, question)
    },

    async updateReferenceQuestion(questionId) {
        return api.post(`/products/questionnaires/questions/${questionId}/relink`)
    },

    async deleteQuestion({questionId, keepVersion}) {
        return api.delete(`/products/questionnaires/questions/${questionId}/${keepVersion}`)
    },

    async getQuestionsByIds(questionIds) {
        return api.post(`/products/questionnaires/questions`, {questionIds})
    },

    // Answerings
    // not used?? delete?
    /*
    async getAnswerings() {
        const response = await api.get(`/products/questionnaires/answerings`)
        response.data = filterForLatestVersion(response.data)
        // usually version has increased
        const answerings = response.data

        // remove duplicate rows due to questionnaire versions
        const tmp = {}

        for (const a of answerings) {
            if (tmp[a.id]) {
                if (a.Questionnaire.version > tmp[a.id].Questionnaire.version) {
                    tmp[a.id] = a
                }
            } else {
                tmp[a.id] = a
            }
        }
        response.data = answerings

        return response
    },
    */

    async exportAnswering(answeringId) {
        return api.get(`/products/questionnaires/answerings/${answeringId}/export`)
    },

    async listAnsweringsByTaskId(taskId) {
        return api.get(`/products/questionnaires/answerings/list-by-task/${taskId}`)
    },

    async getAnswering(id) {
        return api.get(`/products/questionnaires/answerings/${id}`)
    },

    async getNumAnswerings() {
        return api.get(`/products/questionnaires/answerings/count`)
    },

    async updateAnswering(answering) {
        return api.put(`/products/questionnaires/answerings/${answering.id}`, answering)
    },

    async submitAnswering(answering) {
        return api.post(`/products/questionnaires/answerings/${answering.id}`, answering)
    },

    async deleteAnswering(id) {
        return api.delete(`/products/questionnaires/answerings/${id}`)
    },

    async getReusableAnswers({answeringId, reusableAnswerIds}) {
        const response = await api.post(`/products/questionnaires/answerings/${answeringId}/reuseable`, {ids: reusableAnswerIds})

        const mappedResult = {}
        for (const x of response.data) {
            ;(mappedResult[x.reusableAnswerId] = mappedResult[x.reusableAnswerId] || []).push(x)
        }

        response.data = mappedResult

        return response
    },

    async checkIfAnsweringsExists(questionnaireId) {
        return api.get(`/products/questionnaires/${questionnaireId}/answerings/exists`)
    },

    async requestReanswer(answeringId) {
        return api.post(`/products/questionnaires/answerings/${answeringId}/reanswer`)
    },

    async getAnsweringsForQuestionnaire(questionnaireId) {
        return api.get(`/products/questionnaires/${questionnaireId}/answerings`)
    },

    // answers

    async getAnswers(id) {
        return api.get(`/products/questionnaires/answers/${id}`)
    },

    async createAnswer(answer) {
        return api.post(`/products/questionnaires/answers`, answer)
    },

    async updateAnswer(answer) {
        return api.put(`/products/questionnaires/answers/${answer.id}`, answer)
    },

    async rateAnswer({id, score}) {
        return api.put(`/products/questionnaires/answers/${id}/rate`, {
            score
        })
    },

    async commentOnAnswer(answer) {
        return api.post(`/products/questionnaires/answers/${answer.id}/comment`, answer)
    },

    async removeCommentFromAnswer({answerId, version}) {
        return api.delete(`/products/questionnaires/answers/${answerId}/comment/${version}`)
    },

    // categories

    async deleteCategoryFromQuestionnaire({questionnaireId, categoryIndex, keepVersion}) {
        return api.delete(`/products/questionnaires/${questionnaireId}/categories/${categoryIndex}/${keepVersion}`)
    },

    async updateQuestionnaireCategories({categoryId, questionnaireIds}) {
        const response = await api.put(`/products/questionnaires/categories`, {categoryId, questionnaireIds})

        const mappedResult = {}
        for (const x of response.data) {
            ;(mappedResult[x.CategoryId] = mappedResult[x.CategoryId] || []).push(x.QuestionnaireId)
        }

        response.data = mappedResult

        return response
    },

    // attachments

    async removeAttachment({attachmentId, answerId}) {
        return api.delete(`/products/questionnaires/attachments/${attachmentId}`, {data: {answerId}})
    },

    getAttachmentUploadLink() {
        return `${Window.$apiUrl}/api/v1/rest/products/questionnaires/attachments`
    },

    getAttachmentDownloadLink(attachmentId) {
        return `${Window.$apiUrl}/api/v1/rest/products/questionnaires/attachments/${attachmentId}/download`
    },

    async addAttachmentToDataset({attachmentId, validUntil}) {
        return api.post(`/products/questionnaires/attachments/${attachmentId}/dataset`, {data: {validUntil}})
    }

    // not used
    /*
    async getAttachments({answerId, version}) {
        return api.get(`/products/questionnaires/attachments/${answerId}/${version}`)
    },
    */

    /*
    async downloadAttachment(attachmentId) {
        return api.get(`/products/questionnaires/attachments/${attachmentId}/download`, {
            responseType: 'blob'
        })
    },
    */
}
