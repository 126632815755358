function resetColorCodes() {
    window.document.documentElement.style.setProperty('--title-text-color', '#03546f')
    window.document.documentElement.style.setProperty('--v-primary-base', '#00051D')
    window.document.documentElement.style.setProperty('--v-success-base', '#36ddcf')
    window.document.documentElement.style.setProperty('--v-warning-base', '#f6bf28')
    window.document.documentElement.style.setProperty('--v-error-base', '#ff0e61')
}

function applyBranding(getters) {
    if (getters.organisationStyle.pageTitle) {
        document.title = getters.organisationStyle.pageTitle
    }
    if (getters.organisationStyle.primaryColorCode) {
        window.document.documentElement.style.setProperty('--title-text-color', getters.organisationStyle.primaryColorCode)
        window.document.documentElement.style.setProperty('--v-primary-base', getters.organisationStyle.primaryColorCode)
    }
    if (getters.organisationStyle.successColorCode) {
        window.document.documentElement.style.setProperty('--v-success-base', getters.organisationStyle.successColorCode)
    }
    if (getters.organisationStyle.warningColorCode) {
        window.document.documentElement.style.setProperty('--v-warning-base', getters.organisationStyle.warningColorCode)
    }
    if (getters.organisationStyle.errorColorCode) {
        window.document.documentElement.style.setProperty('--v-error-base', getters.organisationStyle.errorColorCode)
    }
    if (getters.organisationStyle.faviconFilename && getters.organisationStyle.id) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.rel = 'shortcut icon'
        link.href = `${Window.$apiUrl}/api/rest/public/organization/${getters.organisationStyle.id}/favicon/display.png`
        document.getElementsByTagName('head')[0].appendChild(link)
    }
}

export default {
    async loadOrganizations({dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/`, {
            method: 'GET',
            credentials: 'include'
        })

        if (response.ok) {
            commit('setOrganizations', await response.json())
        }
    },
    async loadLimitedOrganizationsInfo({dispatch, commit}, {includeLastLogin = false}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/limited?includeLastLogin=${includeLastLogin}`, {
            method: 'GET',
            credentials: 'include'
        })

        if (response.ok) {
            let resp = await response.json()
            commit('setOrganizationsLimited', resp)
        }
    },

    async loadOrganization({dispatch, commit}, companyId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/${companyId}`, {
            method: 'GET',
            credentials: 'include'
        })

        if (response.ok) {
            commit('setOrganization', await response.json())
        }
    },

    async deleteOrganization({state, commit}, {companyId, force = false}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/${companyId}/delete?force=${force}`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            commit(
                'setOrganizations',
                state.organizations.filter((organization) => companyId !== organization.id)
            )
            return true
        } else if (response.status === 400) {
            return false
        }
    },

    async createOrganization({dispatch}, organization, noPoweredBy = false) {
        const data = {...organization}
        delete data.logo
        delete data.logoColor
        delete data.favicon

        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/create`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })

        if (response.ok) {
            const id = await response.json()
            dispatch('updateOrganizationLogo', {
                companyId: id,
                logo: organization.logo,
                logoColor: organization.logoColor,
                favicon: organization.favicon,
                noPoweredBy: noPoweredBy
            })
            return id
        }
    },

    async updateOrganizationLogo({state, dispatch}, {companyId, logo, favicon, logoColor, noPoweredBy = false}) {
        if ((logo && !logo.data) || (favicon && !favicon.data) || (logoColor && !logoColor.data)) {
            const formData = new FormData()

            formData.append('noPoweredBy', noPoweredBy)

            if (logo && !logo.data) {
                formData.append('logo', logo.split(',')[1])
            }

            if (logoColor && !logoColor.data) {
                formData.append('logoColor', logoColor.split(',')[1])
            }

            if (favicon && !favicon.data) {
                formData.append('favicon', favicon.split(',')[1])
            }

            await fetch(`${Window.$apiUrl}/api/rest/organization/${companyId}/update-pictures`, {
                method: 'POST',
                credentials: 'include',
                body: formData
            })

            if (companyId === state.organization.id) {
                dispatch('loadLoginStyle', companyId)
            }
        }
    },

    async uploadScanPermission({dispatch}, {companyId, scanConsent, domains}) {
        const formData = new FormData()
        formData.append('scanConsent', scanConsent)
        formData.append('domains', JSON.stringify(domains))

        await dispatch('POST', {
            url: `${Window.$apiUrl}/api/rest/organization/${companyId}/scan-consent/upload`,
            isFile: true,
            body: formData
        })

        dispatch('loadOrganizations')
    },

    async acceptDeclineScanPermission({dispatch}, {consentId, accept}) {
        await dispatch('POST', {
            url: `${Window.$apiUrl}/api/rest/organization/${consentId}/accept-decline-scan-permission?accept=${accept}`
        })
        dispatch('loadOrganizations')
    },

    async loadLoginStyle({state, commit, dispatch, getters}, id) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/public/organization/${id}/login-style`, {
            method: 'GET',
            credentials: 'include'
        })
        const company = await response.json()

        if (company) {
            commit('setOrganizationStyle', company)
            applyBranding(getters)
        }
    },

    async loadMailContact({state, commit, dispatch}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/mail-contact`, {
            method: 'GET',
            credentials: 'include'
        })

        const json = await response.json()

        if (json.mailContact) {
            commit('setMailContact', json.mailContact)
        }
    },

    async loadContactData({state, commit, dispatch, getters}, id) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/${id}/contact-data`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            return await response.json()
        }
        return null
    },

    async getOrganization({dispatch, commit}, companyId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/${companyId}`, {
            method: 'GET',
            credentials: 'include'
        })

        if (response.ok) {
            return await response.json()
        }
        return undefined
    },

    async updateOrganization({state, dispatch, commit}, {organization, noPoweredBy = false}) {
        const data = {...organization}
        delete data.logo
        delete data.logoColor
        delete data.favicon

        const response = await fetch(`${Window.$apiUrl}/api/rest/organization/${organization.id}/update`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })

        if (response.ok) {
            if (organization.id === state.organization.id) {
                commit('setOrganization', organization)
            }
            dispatch('updateOrganizationLogo', {
                companyId: organization.id,
                logo: organization.logo,
                logoColor: organization.logoColor,
                favicon: organization.favicon,
                noPoweredBy: noPoweredBy
            })
            // await dispatch('loadOrganizations')
        }
        return response
    },

    changeFavicon({getters}, {companyId, filename} = {}) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.rel = 'shortcut icon'

        let faviconFileName = filename || 'favicon'
        if (getters.currentUserOrganization) {
            faviconFileName = getters.currentUserOrganization.faviconFilename
        }
        link.href = `${Window.$apiUrl}/api/rest/public/organization/${companyId || getters.currentUserOrganization.id || 0}/favicon/display.png`
        document.getElementsByTagName('head')[0].appendChild(link)
    },

    async downloadScanConsent({}, params) {
        if (params.consentId && params.document) {
            const consentId = params.consentId
            const document = params.document

            const response = await fetch(`${Window.$apiUrl}/api/rest/organization/${consentId}/scan-consent-file/download`, {
                method: 'GET',
                credentials: 'include'
            })

            if (response.ok) {
                const blob = await response.blob()
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)

                const filename = response.headers.get('filename') || 'consent_' + consentId
                link.download = filename + '.pdf'

                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }
    },

    setPageTitle({getters}, title) {
        if (title) {
            document.title = title
        } else {
            document.title = getters.organisationPageTitle
        }
    }
}
