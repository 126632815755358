function filterForLatestVersion(objects) {
    const tmp = {}

    if (objects) {
        for (let q of objects) {
            if (tmp[q.id]) {
                if (q.version > tmp[q.id].version) {
                    tmp[q.id] = q
                }
            } else {
                tmp[q.id] = q
            }
        }
    }
    return Object.values(tmp)
}

module.exports = {
    filterForLatestVersion: filterForLatestVersion
}