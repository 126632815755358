import QuestionnaireService from '@/services/QuestionnaireService'

function filterForLatestVersion(objects) {
    const tmp = {}

    if (objects) {
        for (let q of objects) {
            if (tmp[q.id]) {
                if (q.version > tmp[q.id].version) {
                    tmp[q.id] = q
                }
            } else {
                tmp[q.id] = q
            }
        }
    }
    return Object.values(tmp)
}

export default {
    async loadQuestionnairesAndAnswerings({dispatch, commit}) {
        const {data: result} = await QuestionnaireService.getQuestionnairesAndAnswerings()

        result.questionnaires = filterForLatestVersion(result.questionnaires)
        result.answerings = filterForLatestVersion(result.answerings)

        commit('setQuestionnaireAnsweringList', result)

        return result
    },

    async loadQuestionnaires({dispatch, commit}) {
        const {data: questionnaires} = await QuestionnaireService.getQuestionnaire()

        commit('setQuestionnaires', filterForLatestVersion(questionnaires))
        return questionnaires
    },

    // questionnaire

    async inviteToQuestionnaire({dispatch}, payload) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/questionnaire/${payload.questionnaireId}/invite`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload) // .email .text
        })
        if (response.ok) {
            // usually version has increased
            const result = await response.json()
            return result
        } else {
            throw new Error()
        }
    }
}
