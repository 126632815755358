export const VendorRiskManagementTour = {
    tourName: 'VendorRiskManagementTour',
    requiresElement: '.guided-tour-vendor-risk-management-dashboard',
    steps: [
        {
            target: '.guided-tour-vendor-risk-management-h1',
            content: 'new.translation.views.VRMWidget.intro.content',
            click: '.guided-tour-vendor-risk-management-dashboard-tab',
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-overview',
            content: 'new.translation.views.VRMWidget.overview.content',
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-matrix',
            content: 'new.translation.views.VRMWidget.matrix.content',
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-suppliers',
            content: 'new.translation.views.VRMWidget.suppliers.content',
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-csv-upload',
            content: 'new.translation.views.VRMWidget.csvUpload.content',
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-supplier-list',
            content: 'new.translation.views.VRMWidget.suppliersList.content',
            click: '.guided-tour-vendor-risk-management-supplier-list-tab',
            navigateBack: '.guided-tour-vendor-risk-management-dashboard-tab',
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-add-supplier-button',
            content: 'new.translation.views.VRMWidget.addSupplierButton.content',
            inDialog: true,
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-add-supplier-dialog',
            content: 'new.translation.views.VRMWidget.addSupplierDialogOne.content',
            click: '.guided-tour-vendor-risk-management-add-supplier-button',
            inDialog: true,
            before: async (value) => {
                if (value == 'previous') {
                    // we have to make sure, that the dialog is visble when navigating back
                    const target = document.querySelector('.dialog button.primary--text')
                    target.click()
                }
            },
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-supplier-category-list',
            content: 'new.translation.views.VRMWidget.suppliersByCategoryList.content',
            click: [{target: '.dialog button.primary--text'}, {target: '.guided-tour-vendor-risk-management-supplier-category-tab'}],
            navigateBack: '.guided-tour-vendor-risk-management-supplier-list-tab',
            padding: true
        },
        {
            target: '.guided-tour-vendor-risk-management-invitation-preview-list',
            content: 'new.translation.views.VRMWidget.invitationList.content',
            click: '.guided-tour-vendor-risk-management-invitation-preview-tab',
            navigateBack: '.guided-tour-vendor-risk-management-supplier-category-tab',
            padding: true
        }
    ]
}
