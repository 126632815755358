import Vue from 'vue'
import router from '../../router'
import {AuthMethodTypes, OAuth2Provider} from '../../../../models/models/enums'

export default {
    setOrganizations(state, organizations) {
        Vue.set(state, 'organizations', organizations)
    },

    setOrganizationsLimited(state, organizations) {
        Vue.set(state, 'organizationsLimited', organizations)
    },

    setOrganization(state, organizations) {
        Vue.set(state, 'organization', organizations)

        if (state.organization.force2fa && !state.userData.hasMFA) {
            router.push({name: 'SET_2FA'})
        }

        return
        if (state.organization?.auth?.password?.allowed && state.organization?.auth?.password?.enforced && !state.userData.auth.password) {
            router.push({name: 'SET_2FA'})
        } else if (
            state.organization?.auth?.sso?.oauth2?.google?.allowed &&
            state.organization?.auth?.sso?.oauth2?.google?.enforced &&
            !state.userData.AuthMethods.some((method) => method.type === AuthMethodTypes.OAUTH2 && method.provider === OAuth2Provider.GOOGLE)
        ) {
            router.push({name: 'SET_OAUTH2'})
        } else if (
            state.organization?.auth?.sso?.oauth2?.microsoft?.allowed &&
            state.organization?.auth?.sso?.oauth2?.microsoft?.enforced &&
            !state.userData.AuthMethods.some((method) => method.type === AuthMethodTypes.OAUTH2 && method.provider === OAuth2Provider.MICROSOFT)
        ) {
            router.push({name: 'SET_OAUTH2'})
        }
        // console.log(state.userData.auth.sso.oauth2.google.id, state.userData.auth, state.organization)
    },

    reloadOrganizationLogo(state, organization) {
        for (const org of state.organizations) {
            if (org.id === organization.id) {
                org.logoFilename = organization.logoFilename
                org.logoColorFilename = organization.logoColorFilename
                org.faviconFilename = organization.faviconFilename
                Vue.set(state, 'organizations', state.organizations)
                return
            }
        }
    },

    setMailContact(state, mailContact) {
        Vue.set(state, 'mailContact', mailContact)
    },

    setOrganizationStyle(state, organizationStyle) {
        Vue.set(state, 'organizationStyle', organizationStyle)
    }
}
