import Vue from 'vue'
import VueRouter from 'vue-router'

//import categories from '@/store/categories'
import categories from '@/../../models/shared/categories'
//import textTranslation from '@/store/findingTextTranslation'

import textTranslation from '@/../../models/shared/findingTextTranslation'

const dayjs = require('dayjs')

Vue.use(VueRouter)

const routes = [
    {
        path: '/release-notes',
        name: 'RELEASE_NOTES',
        component: () => import('../views/ReleaseNotes'),
        props: (route) => ({
            propReleaseNotes: route.params.propReleaseNotes,
            backRoute: route.params.backRoute
        })
    },
    {
        path: '/edit-release-notes',
        name: 'EDIT_RELEASE_NOTES',
        component: () => import('../views/EditReleaseNotes')
    },
    {
        path: '/give-consent/:token',
        name: 'GIVE_CONSENT',
        component: () => import('../views/acceptPages/GiveConsent'),
        props: (route) => ({
            companyId: route.query.companyId,
            token: route.params.token
        })
    },
    {
        path: '/my-profile/set-2fa',
        name: 'SET_2FA',
        component: () => import('../views/myProfile/Set2FA')
    },
    {
        path: '/my-profile/remove-2fa',
        name: 'REMOVE_2FA',
        component: () => import('../views/myProfile/Remove2FA')
    },
    {
        path: '/print/report/:scanId/management',
        name: 'PrintPage',
        component: () => import('../views/printManagementReport/PrintPage'),
        props: (route) => {
            return {
                scanId: route.params.scanId,
                sharedLinkId: route.query.sharedLinkId,
                onlyCurrent: route.query.onlyCurrent,
                demo: route.query.demo === 'true'
            }
        }
    },
    {
        path: '/print/report/:scanId/filter',
        name: 'PrintFilterPage',
        component: () => import('../views/printFilterReport/PrintPage'),
        props: (route) => {
            return {
                scanId: route.params.scanId,
                sharedLinkId: route.query.sharedLinkId,
                groupIds: route.query.groupIds
            }
        }
    },
    {
        path: '/print/report/:scanId/inventory',
        name: 'PrintInventoryPage',
        component: () => import('../views/printInventoryReport/PrintPage'),
        props: (route) => {
            return {
                scanId: route.params.scanId,
                sharedLinkId: route.query.sharedLinkId
            }
        }
    },
    {
        path: '/print/vendor-risk-management/:productId',
        name: 'PrintVRMPage',
        component: () => import('../views/printVRMReport/PrintPage'),
        props: (route) => {
            return {
                productId: route.params.productId
            }
        }
    },
    {
        path: '/print/questionnaire/:answeringId',
        name: 'PrintQuestionnaireReport',
        component: () => import('../views/printQuestionnaireReport/PrintPage'),
        props: (route) => {
            return {
                answeringId: route.params.answeringId
            }
        }
    },
    {
        path: '/payment-success',
        name: 'Payment-success',
        component: () => import('../views/payment/Payment-success')
    },
    {
        path: '/payment-cancel',
        name: 'Payment-cancel',
        component: () => import('../views/payment/Payment-cancel')
    },
    {
        path: '/',
        name: 'ProductOverview',
        component: () => import('../views/productOverview/ProductOverview')
    },
    {
        path: '/my-profile',
        name: 'My_Profile',
        component: () => import('../views/myProfile/MyProfile')
    },
    {
        path: '/admin',
        name: 'AdminPage',
        component: () => import('../views/admin/AdminPage')
    },
    {
        path: '/cyberrisk-analysis/:taskId',
        name: 'CyberriskAnalysisOverview',
        component: () => import('../views/cyberriskAnalysis/Overview'),
        props: (route) => ({
            taskId: route.params.taskId,
            comesFrom: route.params.comesFrom
        })
    },
    {
        path: '/cyberrisk-comparison/:id',
        name: 'CyberriskComparision',
        component: () => import('../views/cyberriskComparison/Overview'),
        props: (route) => ({
            productId: route.params.id
        })
    },
    {
        path: '/scans',
        name: 'AnalysisOverview',
        component: () => import('../views/analysisOverview/AnalysisOverview'),
        props: (route) => ({
            taskId: route.query.taskId,
            domain: route.query.domain,
            view: 'ProductOverview'
        })
    },
    {
        path: '/report/:id/',
        name: 'ReportPage',
        component: () => import('../views/report/ReportPage'),
        props: (route) => {
            return {
                differenceScanId: route.query.diff,
                taskId: route.query.taskId,
                id: route.params.id,
                backToStartPage: route.params.backToStartPage
            }
        }
    },
    {
        path: '/report/:id/filter/:taskId',
        name: 'TagFilterPage',
        component: () => import('../views/filterShare/TagFilterPage'),
        props: (route) => {
            return {
                id: route.params.id,
                taskid: route.params.taskId
            }
        }
    },
    {
        path: '/report/:id/filter/:groupId/apply',
        name: 'TagFilterApplyPage',
        component: () => import('../views/filterShare/TagFilterApplyPage'),
        props: (route) => {
            return {
                id: route.params.id,
                groupId: route.params.groupId
            }
        }
    },
    {
        path: '/report/:scanid/filter/:id/add',
        name: 'AddFilterTagsPage',
        component: () => import('../views/filterShare/AddEditFilterTagsPage'),
        props: (route) => {
            // here :id is task id
            return {
                scanId: route.params.scanid,
                id: route.params.id
            }
        }
    },
    {
        path: '/report/:scanid/filter/:id/edit',
        name: 'EditFilterTagsPage',
        component: () => import('../views/filterShare/AddEditFilterTagsPage'),
        props: (route) => {
            // here :id is group id
            return {
                scanId: route.params.scanid,
                id: route.params.id,
                edit: true
            }
        }
    },
    {
        path: '/report/:id/findings',
        name: 'FindingsTab',
        component: () => import('../views/report/FindingsTab'),
        props: true
    },
    {
        path: '/report/:id/hosts',
        name: 'HostList',
        component: () => import('../views/report/HostList'),
        props: true
    },
    {
        path: '/report/:id/host',
        name: 'SharedHost',
        component: () => import('../views/sharedLink/SharedHost'),
        props: (route) => ({
            scanId: route.params.id,
            hostIp: route.query.ip
        })
    },
    {
        path: '/report/:id/graph',
        name: 'Graph',
        component: () => import('../views/report/Graph')
    },
    {
        path: '/difference/:id',
        name: 'Difference',
        component: () => import('../views/report/Graph'),
        props: (route) => ({
            scanId1: route.query.scanId1,
            scanId2: route.query.scanId2
        })
    },
    {
        path: '/risk-comparison/:id',
        name: 'RiskComparision',
        component: () => import('../views/riskComparision/RiskComparision'),
        props: (route) => ({
            productId: route.params.id
        })
    },
    {
        path: '/risk-comparison/:id/scans/:domain',
        name: 'RiskComparisionScansOverview',
        component: () => import('../views/analysisOverview/AnalysisOverview'),
        props: (route) => ({
            taskId: route.params.id,
            domain: route.params.domain,
            view: 'RiskComparision'
        })
    },
    {
        path: '/risk-list/:id',
        name: 'RiskList',
        component: () => import('../views/riskList/SupplierRiskList'),
        props: (route) => ({
            productId: route.params.id
        })
    },
    {
        path: '/risk-list/:id/scans/:domain',
        name: 'RiskListScansOverview',
        component: () => import('../views/analysisOverview/AnalysisOverview'),
        props: (route) => ({
            taskId: route.query.taskId,
            view: 'RiskList'
        })
    },
    {
        path: '/organizations',
        name: 'Organizations',
        component: () => import('../views/organizations/Organizations')
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('../views/users/Users')
    },
    {
        path: '/own-company',
        name: 'OwnCompany',
        component: () => import('../views/ownCompany/OwnCompany')
    },
    {
        path: '/configs',
        name: 'Configs',
        component: () => import('../views/hiddenConfigs/HiddenConfigs')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/Login'),
        props: (route) => ({
            sessionExpired: !!route.query.sessionExpired,
            companyId: route.query.companyId,
            passwordReset: route.query.passwordReset,
            requestedPath: route.params.requestedPath
        })
    },
    {
        path: '/password-reset/:id',
        name: 'PasswordReset',
        component: () => import('../views/passwordReset/PasswordReset'),
        props: (route) => ({
            companyId: route.query.companyId,
            passwordResetToken: route.params.id
        })
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('../views/passwordReset/PasswordReset'),
        props: (route) => ({
            changePasswordView: true
        })
    },
    {
        path: '/shared',
        name: 'Shared',
        component: () => import('../views/sharedLink/SharedLink'),
        props: (route) => ({
            id: route.query.id,
            sid: route.query.sid,
            host: route.query.host
        })
    },
    {
        path: '/shared/:taskId/:groupId',
        name: 'SharedFilter',
        component: () => import('../views/sharedLink/SharedUserFilter'),
        props: (route) => ({
            taskId: route.params.taskId,
            groupId: route.params.groupId
        })
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: () => import('../views/pricing/Pricing')
    },
    {
        path: '/bookings',
        name: 'Bookings',
        component: () => import('../views/bookings/Bookings')
    },
    {
        path: '/vendor-risk-management/supplier/:supplierId/:supplierToken',
        name: 'VendorRiskManagementSupplier',
        component: () => import('../views/vendorRiskManagement/supplier/CheckToken'),
        props: (route) => ({
            supplierId: route.params.supplierId,
            supplierToken: route.params.supplierToken
        })
    },
    {
        path: '/vendor-risk-management/:productId',
        name: 'VendorRiskManagement',
        component: () => import('../views/vendorRiskManagement/customer/Index'),
        props: (route) => ({
            productId: route.params.productId
        })
    },
    {
        path: '/vendor-risk-management/:productId/:tab',
        name: 'VendorRiskManagementWithTab',
        component: () => import('../views/vendorRiskManagement/customer/Index'),
        props: (route) => ({
            productId: route.params.productId,
            tab: route.params.tab
        })
    },

    {
        path: '/questionnaires',
        name: 'Questionnaires',
        component: () => import('../views/questionnaire/Overview'),
        props: (route) => ({
            productId: route.params.productId
        })
    },

    {
        path: '/questionnaires/edit/:questionnaireId',
        name: 'QuestionnaireEditor',
        component: () => import('../views/questionnaire/editor/QuestionnaireEditor'),
        props: (route) => ({
            questionnaireId: route.params.questionnaireId
        })
    },

    {
        path: '/questionnaires/answer/:answeringId',
        name: 'AnsweringEditor',
        component: () => import('../views/questionnaire/editor/AnsweringEditor'),
        props: (route) => ({
            answeringId: route.params.answeringId
        })
    },

    {
        path: '/questionnaires/view/:answeringId',
        name: 'AnsweringViewer',
        component: () => import('../views/questionnaire/viewer/AnsweringViewer'),
        props: (route) => ({
            answeringId: route.params.answeringId
        })
    },

    {
        path: '/unsubscribe/:tokenType?/:token?',
        name: 'Unsubscribe',
        component: () => import('../views/Unsubscribe'),
        props: (route) => ({
            token: route.params.token,
            tokenType: route.params.tokenType
        })
    }

    // {
    //     path: '/actions',
    //     name: 'Actions',
    //     component: () => import('../views/actions/Actions'),
    //     props: (route) => ({
    //         productId: route.params.productId
    //     })
    // }
]

//const {getDescription, getRisks, getRemedy} = require('@/store/findingTextTranslation')

const riskPotential = {
    HIGH: 'high',
    MED: 'med',
    LOW: 'low'
}

const riskScoreLimit = {
    HIGH: 85,
    MED: 60
}

const riskScoreCategories = [
    {
        name: 'Bad',
        from: 0,
        to: riskScoreLimit.MED - 1
    },
    {
        name: 'Medium',
        from: riskScoreLimit.MED,
        to: riskScoreLimit.HIGH - 1
    },
    {
        name: 'Good',
        from: riskScoreLimit.HIGH,
        to: 100
    }
]

function getHighRiskColorVariable() {
    return 'var(--v-error-base)'
}

function getMedRiskColorVariable() {
    return 'var(--v-warning-base)'
}

function getLowRiskColorVariable() {
    return 'var(--v-success-base)'
}

function getHighRiskColorVariableValue() {
    return '--v-error-base'
}

function getMedRiskColorVariableValue() {
    return '--v-warning-base'
}

function getLowRiskColorVariableValue() {
    return '--v-success-base'
}

function getRiskPotentialFromGrade(grade) {
    if (grade > 3) {
        return riskPotential.HIGH
    } else if (grade > 0) {
        return riskPotential.MED
    } else {
        return riskPotential.LOW
    }
}

function getColorFromRelativeScore(subRating, type) {
    const potential = getRelativeRiskPotentialFromScore(subRating, type)
    switch (potential) {
        case riskPotential.HIGH: {
            return getHighRiskColorVariable()
        }
        case riskPotential.MED: {
            return getMedRiskColorVariable()
        }
        case riskPotential.LOW: {
            return getLowRiskColorVariable()
        }
    }
    return getLowRiskColorVariable()
}

function getRelativeRiskPotentialFromScore(subRating, type) {
    let deathRanges = {
        app: -15,
        web: -10,
        ssl: -10,
        infection: -10,
        dns: -10,
        network: -10,
        dsgvo: -5
    }

    if (deathRanges[type] === undefined) return getRiskPotentialFromGrade(subRating.grade)
    if (subRating.score <= 2 * (deathRanges[type] / 3)) return riskPotential.HIGH

    if (subRating.score <= -1 || subRating.counts.high > 0)
        // 1 * (deathRanges[type] / 3))
        return riskPotential.MED

    return riskPotential.LOW
}

Vue.mixin({
    methods: {
        capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
        shortenString: function (title, length) {
            if (title.length > length) {
                return title.substring(0, length) + '...'
            }
            return title
        },
        getTitleFromType: function (title, id, type) {
            if (type == 'app_nested' || type == 'network') return id
            return title
        },
        getColorFromScore(score) {
            let colorClass = {color: getLowRiskColorVariable()}
            if (score < riskScoreLimit.HIGH) colorClass = {color: getMedRiskColorVariable()}
            if (score < riskScoreLimit.MED) colorClass = {color: getHighRiskColorVariable()}

            return colorClass
        },
        getColorCodeFromScore(score) {
            let colorCode = getLowRiskColorVariable()
            if (score < riskScoreLimit.HIGH) colorCode = getMedRiskColorVariable()
            if (score < riskScoreLimit.MED) colorCode = getHighRiskColorVariable()

            return colorCode
        },
        getColorValueFromScore(score) {
            let colorCode = getLowRiskColorVariableValue()
            if (score < riskScoreLimit.HIGH) colorCode = getMedRiskColorVariableValue()
            if (score < riskScoreLimit.MED) colorCode = getHighRiskColorVariableValue()

            return colorCode
        },
        getColorFromGrade(grade) {
            const potential = getRiskPotentialFromGrade(grade)
            switch (potential) {
                case riskPotential.HIGH: {
                    return getHighRiskColorVariable()
                }
                case riskPotential.MED: {
                    return getMedRiskColorVariable()
                }
                case riskPotential.LOW: {
                    return getLowRiskColorVariable()
                }
            }
            return getLowRiskColorVariable()
        },
        getRiskPotentialFromGrade,
        getRelativeRiskPotentialFromScore,
        getHighRiskColorVariable,
        getMedRiskColorVariable,
        getLowRiskColorVariable,
        isHighRisk(grade) {
            return getRiskPotentialFromGrade(grade) === riskPotential.HIGH
        },
        isMedRisk(grade) {
            return getRiskPotentialFromGrade(grade) === riskPotential.MED
        },
        isLowRisk(grade) {
            return getRiskPotentialFromGrade(grade) === riskPotential.LOW
        },
        getRiskScoreCategories() {
            return riskScoreCategories
        },
        getBackgroundColorFromGrade: function (grade) {
            if (grade < 1) return {'background-color': getLowRiskColorVariable()}
            else if (grade > 0 && grade < 4) return {'background-color': getMedRiskColorVariable()}
            else return {'background-color': getHighRiskColorVariable()}
        },
        getBackgroundColorFromScore: function (score) {
            let colorClass = {'background-color': getLowRiskColorVariable()}
            if (score < riskScoreLimit.HIGH) colorClass = {'background-color': getMedRiskColorVariable()}
            if (score < riskScoreLimit.MED) colorClass = {'background-color': getHighRiskColorVariable()}

            return colorClass
        },
        getColorFromRelativeScore,
        getBackgroundColorFromRelativeScore(subRating, type) {
            return {'background-color': getColorFromRelativeScore(subRating, type)}
        },

        prepareScore: function (score, category) {
            if (score == 0) {
                if (category !== 'breach') {
                    return 0
                } else {
                    return '--'
                }
            }
            return score.toFixed(2)
        },
        getDate(timestamp, format) {
            try {
                timestamp = parseInt(timestamp)
                if (timestamp === Number.MAX_SAFE_INTEGER) {
                    return '--'
                }
                if (format) {
                    return dayjs(timestamp).format(format)
                } else {
                    return dayjs(timestamp).format('DD.MM.YYYY')
                }
            } catch {
                return timestamp
            }
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object
        },
        getDescription(type, id, title) {
            return textTranslation.getDescription(type, id, title)
        },
        getRisks(type, id, title) {
            return textTranslation.getRisks(type, id, title)
        },
        getRemedy(type, id, title) {
            return textTranslation.getRemedy(type, id, title)
        }
    },
    data: function () {
        return {
            catHelper: categories,
            textTranslation: textTranslation
        }
    }
})

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
