<template>
    <div v-if="targetElement" v-bind:class="{'v-step--sticky': isSticky}" class="v-step" :id="'v-step-' + hash" :ref="'v-step-' + hash">
        <slot name="header">
            <div v-if="step.header" class="v-step__header">
                <div v-if="step.header.title" v-html="step.header.title"></div>
            </div>
        </slot>

        <slot name="content">
            <div class="v-step__content">
                <div v-if="step.content" v-html="step.content"></div>
                <div v-else>This is a demo step! The id of this step is {{ hash }} and it targets {{ step.target }}.</div>
            </div>
        </slot>

        <slot name="actions">
            <div class="v-step__buttons">
                <button @click.prevent="skip" v-if="!isLast && isButtonEnabled('buttonSkip')" class="v-step__button v-step__button-skip">
                    {{ labels.buttonSkip }}
                </button>
                <button @click.prevent="previousStep" v-if="!isFirst && isButtonEnabled('buttonPrevious')" class="v-step__button v-step__button-previous">
                    {{ labels.buttonPrevious }}
                </button>
                <button @click.prevent="nextStep" v-if="!isLast && isButtonEnabled('buttonNext')" class="v-step__button v-step__button-next">
                    {{ labels.buttonNext }}
                </button>
                <button @click.prevent="finish" v-if="isLast && isButtonEnabled('buttonStop')" class="v-step__button v-step__button-stop">
                    {{ labels.buttonStop }}
                </button>
            </div>
        </slot>

        <div v-if="!step.popInner" class="v-step__arrow" :class="{'v-step__arrow--dark': step.header && step.header.title}" data-popper-arrow></div>
    </div>
</template>

<script>
import {createPopper} from '@popperjs/core'
import sum from 'hash-sum'
import {DEFAULT_STEP_OPTIONS, HIGHLIGHT} from '../shared/constants'
import {delay, waitForElement} from '../shared/functions'
import {DEFAULT_OPTIONS} from '../shared/constants'

// for better smooth scrolling in tours
import { smoothScroll } from '../shared/smoothScroll'

export default {
    name: 'v-step',
    props: {
        step: {
            type: Object
        },
        previousStep: {
            type: Function
        },
        nextStep: {
            type: Function
        },
        stop: {
            type: Function
        },
        skip: {
            type: Function,
            default: function () {
                this.stop()
            }
        },
        finish: {
            type: Function,
            default: function () {
                this.stop()
            }
        },
        isFirst: {
            type: Boolean
        },
        isLast: {
            type: Boolean
        },
        labels: {
            type: Object
        },
        enabledButtons: {
            type: Object
        },
        highlight: {
            type: Boolean
        },
        stopOnFail: {
            type: Boolean
        },
        debug: {
            type: Boolean
        },
        isMovingBackwards: {
            type: Boolean
        },
        needsSkipAlreadyShownStepsWorkaround: {
            type: Boolean
        }
    },
    data() {
        return {
            hash: typeof this.step.target === 'string' ? sum(this.step.target) : sum(this.step.checkId),
            targetElement: typeof this.step.target === 'string' ? document.querySelector(this.step.target) : this.step.target,
            isDestroyed: false,
            shouldShow: true
        }
    },
    computed: {
        params() {
            return {
                ...DEFAULT_STEP_OPTIONS,
                ...{highlight: this.highlight}, // Use global tour highlight setting first
                ...{enabledButtons: Object.assign({}, this.enabledButtons)},
                ...this.step.params // Then use local step parameters if defined,
            }
        },
        /**
         * A step is considered sticky if it has no target.
         */
        isSticky() {
            return !this.step.target
        },
        visible() {
            return !this.step.buttonsDisabled
        }
    },
    methods: {
        async createStep() {
            if (this.debug) {
                console.log('[Vue Tour] The target element ' + this.step.target + ' of .v-step[id="' + this.hash + '"] is:', this.targetElement)
            }
            if (this.isSticky) {
                document.body.appendChild(this.$refs['v-step-' + this.hash])
            } else {
                this.shouldShow = true // !this.$store.state.tours.doNotShowAgain[this.step.stepId]

                if (this.step.waitFor != undefined && !this.targetElement && !this.isDestroyed) {
                    this.targetElement = await waitForElement(this.step.target, this.$t('tours.waitForSpinnerText'), this.step.waitFor)
                }

                // is destroyed in the meantime? like it was in a dialog and the directive is now unmounted
                if (this.isDestroyed) return;

                if (this.targetElement && this.shouldShow) {
                    //if (this.needsSkipAlreadyShownStepsWorkaround) await delay(500)

                    this.enableScrolling()
                    this.createHighlight()

                    // initialize inner pop
                    if (this.step.popInner && !this.popInnerParams) {
                        this.popInnerParams = {...this.params}
                        this.popInnerParams.modifiers = this.params.modifiers.filter((x) => x.name !== 'offset' && x.name !== 'arrow')
                        this.popInnerParams.modifiers.push({
                            name: 'offset',
                            options: {
                                offset: ({placement, reference, popper}) => {
                                    return [0, -popper.height * 0.9]
                                }
                            }
                        })
                    }
                    createPopper(this.targetElement, this.$refs['v-step-' + this.hash], this.step.popInner ? this.popInnerParams : this.params)
                    
                    this.$emit('created', this.step)

                    this.$store.commit('doNotShowTourStepAgain', this.step.stepId)

                    // allow steps during running tour to show again
                    this.$store.commit('setAllowShowAgain', this.step.stepId)
                } else {
                    if (this.debug) {
                        console.error('[Vue Tour] The target element ' + this.step.target + ' of .v-step[id="' + this.hash + '"] does not exist!')
                    }

                    if (this.shouldShow) this.$emit('targetNotFound', this.step)

                    if (this.stopOnFail && this.shouldShow) {
                        this.stop()
                    } else {
                        if (this.isMovingBackwards) {
                            await this.previousStep()
                        } else {
                            await this.nextStep()
                        }
                    }
                }
            }
        },
        enableScrolling() {
            this.targetElement.classList.add('vue-tour-target')

            // this does work well, but not when inside overflow: hidden!
            // works in overflow: clip thought
            // will not scroll when in dialog
            // setTimeout(() => this.targetElement.scrollIntoView({behavior: 'smooth'}), 100)

            
            smoothScroll({
                scrollTo: this.targetElement,
                duration: 400,
                offset: -200 * window.devicePixelRatio,
                updateHistory: false,
                container: this.step.scrollContainer ? document.querySelector(this.step.scrollContainer) : window,
                checkViewport: this.step.inDialog // only test to not scroll in dialogs
                // still a missing margin, when trying on every element
            })
        },
        isHighlightEnabled() {
            if (this.debug) {
                console.log(`[Vue Tour] Highlight is ${this.params.highlight ? 'enabled' : 'disabled'} for .v-step[id="${this.hash}"]`)
            }
            return this.params.highlight
        },
        createHighlight() {
            document.body.classList.add(HIGHLIGHT.classes.active)
            const transitionValue = window.getComputedStyle(this.targetElement).getPropertyValue('transition')
            // Make sure our background doesn't flick on transitions
            if (transitionValue !== 'all 0s ease 0s') {
                this.targetElement.style.transition = `${transitionValue}, ${HIGHLIGHT.transition}`
            }
            this.targetElement.classList.add(HIGHLIGHT.classes.targetHighlighted)
            if (this.step.padding) this.targetElement.classList.add(HIGHLIGHT.classes.targetPadding)
            // The element must have a position, if it doesn't have one, add a relative position class
            if (!this.targetElement.style.position) {
                this.targetElement.classList.add(HIGHLIGHT.classes.targetRelative)
            }
        },
        removeHighlight() {
            try {
                const target = this.targetElement
                const currentTransition = this.targetElement.style.transition
                this.targetElement.classList.remove(HIGHLIGHT.classes.targetHighlighted)
                this.targetElement.classList.remove(HIGHLIGHT.classes.targetRelative)
                this.targetElement.classList.remove(HIGHLIGHT.classes.targetPadding)
                // Remove our transition when step is finished.
                if (currentTransition.includes(HIGHLIGHT.transition)) {
                    setTimeout(() => {
                        if (target) {
                            target.style.transition = currentTransition.replace(`, ${HIGHLIGHT.transition}`, '')
                        }
                    }, 0)
                }
            } catch (e) {
                // in case we navigated the target is gone
            }
        },
        isButtonEnabled(name) {
            return this.params.enabledButtons.hasOwnProperty(name) ? this.params.enabledButtons[name] : true
        }
    },
    async mounted() {
        await this.createStep()
    },
    destroyed() {
        this.removeHighlight()
        this.isDestroyed = true
    }
}
</script>

<style lang="scss" scoped>
.v-step {
    background: none;
    color: inherit;
    max-width: 320px;
    border-radius: 3px;
    box-shadow: none;
    padding: 0;
    pointer-events: auto;
    text-align: inherit;
    z-index: 10000;
    &--sticky {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & .v-step__arrow {
            display: none;
        }
    }
}
.v-step__arrow,
.v-step__arrow::before {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
}
.v-step__arrow {
    visibility: hidden;
    &--dark {
        &:before {
            background: white;
        }
    }
}
.v-step__arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    margin-left: -5px;
}
.v-step[data-popper-placement^='top'] > .v-step__arrow {
    bottom: -5px;
}
.v-step[data-popper-placement^='bottom'] > .v-step__arrow {
    top: -5px;
}
.v-step[data-popper-placement^='right'] > .v-step__arrow {
    left: -5px;
}
.v-step[data-popper-placement^='left'] > .v-step__arrow {
    right: -5px;
}
/* Custom */
.v-step__header {
    margin: -1rem -1rem 0.5rem;
    padding: 0.5rem;
    background-color: #454d5d;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.v-step__content {
    margin: 0 0 1rem 0;
}
.v-step__button {
    background: transparent;
    border: 0.05rem solid white;
    border-radius: 0.1rem;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8rem;
    height: 1.8rem;
    line-height: 1rem;
    outline: none;
    margin: 0 0.2rem;
    padding: 0.35rem 0.4rem;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease;
    vertical-align: middle;
    white-space: nowrap;
    &:hover {
        background-color: rgba(white, 0.95);
        color: #50596c;
    }
}

/* overrides arrow color */
.vue-tour .v-step__arrow--dark:before {
    background: white;
}
</style>
