<template>
    <v-dialog :width="width" :value="value" @input="(value) => $emit('input', value)" :content-class="contentClass" no-click-animation>
        <v-card class="dialog">
            <div v-if="title" class="title">
                <h2 v-html="title"></h2>
                <v-icon v-if="!hideClose" @click="$emit('close', false)" class="dialog-close-icon">mdi-close</v-icon>
            </div>

            <slot>
                <p class="default-text" v-html="text"></p>
            </slot>

            <div v-if="cancelText || submitText" class="actions">
                <v-btn v-if="deleteText" style="grid-column: 1" color="error" text @click="$emit('delete')">
                    {{ deleteText }}
                </v-btn>
                <v-btn v-if="cancelText" style="grid-column: 3" color="primary" @click.stop="$emit('cancel')" text>{{ cancelText }} </v-btn>
                <v-btn v-if="submitText" style="grid-column: 4" elevation="0" :disabled="disableSubmit" @click.stop="$emit('submit')" :color="submitButtonColor"
                    >{{ submitText }}
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Dialog',
    props: {
        value: {
            type: Boolean
        },
        title: {
            type: String
        },
        deleteText: {
            type: String
        },
        cancelText: {
            type: String
        },
        submitText: {
            type: String
        },
        submitButtonColor: {
            type: String,
            default: 'primary'
        },
        text: {
            type: String
        },
        hideClose: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '600px'
        },
        contentClass: {
            type: String,
            default: ''
        },
        disableSubmit: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">
.dialog {
    display: grid;
    grid-row-gap: 20px;
    padding: 20px 30px;

    @media (max-width: 500px) {
        padding: 10px 15px;
    }
}

.title {
    display: grid;
    grid-template-columns: 1fr 32px;
    margin-right: -8px;
}

.default-text {
    min-height: 25px;
}

.actions {
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content;
    grid-column-gap: 10px;
}
</style>
