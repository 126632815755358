import Vue from 'vue'

export default {
    setConfigs(state, configs) {
        Vue.set(state, 'configs', configs)
    },

    addTag(state, tagItem) {
        state.tags.configTags.push(tagItem)
    },

    addTags(state, params) {
        for (const tag of params.tagList) {
            state.tags.configTags.push({tag: tag, hostIp: params.hostIp})
        }
    },

    removeTag(state, tagItem) {
        const index = state.tags.configTags.findIndex((i) => i.tag == tagItem.tag)

        if (index > -1) {
            state.tags.configTags.splice(index, 1)
        }
    },

    addItemConfig,

    removeItemConfig(state, config) {
        //todo inspect here
        // Item was marked as FP and is removed from list

        if (config.type === -1) {
            const index = state.fpConfig.serverConfigChanges.findIndex((i) => i.hostIp == config.hostIp)
            if (index > -1) {
                state.fpConfig.serverConfigChanges.splice(index, 1)
            } else {
                addItemConfig(state, config)
            }
        } else {
            const index = state.fpConfig.reportConfig.findIndex((i) => i.item.uuid == config.item.uuid)
            if (index > -1) {
                if (config.type === 0) {
                    state.fpConfig.reportConfig.splice(index, 1)
                } else if (config.type === 1) {
                    // If config is in reportConfig
                    if (state.fpConfig.reportConfig[index][config.param.ip]) {
                        // Do a splice and push to call computed properties in components
                        const tmpItem = state.fpConfig.reportConfig[index]
                        state.fpConfig.reportConfig.splice(index, 1)
                        delete tmpItem[config.param.ip]
                        // Length === 4: item, useTitle, type, param -> no more configs inside
                        if (Object.keys(tmpItem).length > 4) {
                            state.fpConfig.reportConfig.push(tmpItem)
                        }
                    }
                    // Otherwise it's in scanConfig, so remove it
                    else {
                        addItemConfig(state, config)
                    }
                } else if (config.type === 2) {
                    // If config is in reportConfig
                    if (state.fpConfig.reportConfig[index][config.param.location]) {
                        const tmpItem = state.fpConfig.reportConfig[index]
                        state.fpConfig.reportConfig.splice(index, 1)
                        delete tmpItem[config.param.location]
                        // Length === 4: item, useTitle, type, param -> no more configs inside
                        if (Object.keys(tmpItem).length > 4) {
                            state.fpConfig.reportConfig.push(tmpItem)
                        }
                    }
                    // Otherwise it's in scanConfig, so remove it
                    else {
                        addItemConfig(state, config)
                    }
                }
            }
            // Item is in scanConfig and needs to be removed
            else {
                addItemConfig(state, config)
            }
        }
    },

    setScanConfig(state, value) {
        // ToDo: Config restructuring, remove everything except settings
        if (value.Config && value.Config.settings) {
            Vue.set(state, 'scanConfig', {...value.Config.settings, configData: value})
        }
    },

    setCurrentScan(state, value) {
        Vue.set(state, 'currentScan', value)
    },

    resetConfig(state) {
        // Used to trigger https://vuejs.org/v2/guide/list.html#Array-Change-Detection
        state.fpConfig.reportConfig.splice(0, state.fpConfig.reportConfig.length)
        state.tags.configTags.splice(0, state.tags.configTags.length)
    },

    setConfigProcessing(state, value) {
        Vue.set(state.fpConfig, 'configProcessing', value)
    },

    setTagFilter(state, value) {
        Vue.set(state.tags, 'tagFilter', value)
    },

    clearTagFilter(state) {
        Vue.set(state.tags, 'tagFilter', [])
    },

    setTagRating(state, value) {
        Vue.set(state.tags, 'rating', value)
    },

    setTagOverlayApplied(state, value) {
        Vue.set(state.tags, 'tagOverlayApplied', value)
    },

    setTagGroup(state, value) {
        Vue.set(state.tags, 'tagGroup', value)
    },

    setModifyItemConfig(state, value) {
        Vue.set(state, 'canModifyItemConfig', value)
    },

    setHostTags(state, value) {
        let tags = {}
        for (const host of value) {
            if (host.tags) {
                for (const tag of host.tags) {
                    if (tag in tags) {
                        tags[tag] += 1
                    } else {
                        tags[tag] = 1
                    }
                }
            }
        }
        /*
        tags = Object.keys(tags).map((key) => {
            return {
                value: key,
                count: tags[key]
            }
        })
        */
        Vue.set(state.tags, 'hostTags', tags)
    },

    setRerateLockStatus(state, value) {
        Vue.set(state, 'rerateLocked', value)
    }
}

function addItemConfig(state, config) {
    /*
             this.addItemConfig({
                item: this.item,
                useTitle: this.useTitle,
                falsePositive: this.falsePositive,
                reason: this.notes,
                grade: this.selectedGrade,
                delete: this.delete,
                type: this.type,
                param: this.param
            })
     */

    let index
    let item = {}

    if (config.type === -1) {
        index = state.fpConfig.serverConfigChanges.findIndex((i) => {
            return i.hostIp == config.item.hostIp
        })
        if (index > -1) {
            //exisitiert schon eine config?

            //todo fix?
            // Only save the configs for this UUID when type > 0 (IP or Location)
            // Configs are withdrawn for Locations and IPs when "main" item is added

            item = state.fpConfig.serverConfigChanges[index]

            state.fpConfig.serverConfigChanges.splice(index, 1)
        }
    } else {
        index = state.fpConfig.reportConfig.findIndex((i) => i.item.uuid == config.item.uuid)
        if (index > -1) {
            //exisitiert schon eine config?

            //todo fix?
            // Only save the configs for this UUID when type > 0 (IP or Location)
            // Configs are withdrawn for Locations and IPs when "main" item is added
            if (config.type > 0) {
                item = state.fpConfig.reportConfig[index]
            }
            state.fpConfig.reportConfig.splice(index, 1)
        }
    }

    item.item = config.item
    item.useTitle = config.useTitle
    item.type = config.type
    item.param = config.param

    const tmpConfig = {
        delete: config.delete,
        falsePositive: config.falsePositive,
        reason: config.reason,
        grade: config.grade,
        contact: config.contact,
        parentIp: config.param && config.param.ip && config.type === 2 ? config.param.ip : false,
        parentUUID: config.parentUUID ? config.parentUUID : false,
        deleteConfig: config.deleteConfig,
        hostIp: config.type === -1 ? item.item.ip : undefined,
        type: config.type
    }

    if (config?.expiresAt) {
        tmpConfig['expiresAt'] = config.expiresAt
    }

    // Put the item on the matching key
    if (config.type === 0) {
        item.all = tmpConfig
    } else if (config.type === 1 && config.param.ip) {
        // Remove all locations which are related to this IP
        //todo fix?!
        for (const key of Object.keys(item)) {
            if (item[key].parentIp === config.param.ip) {
                delete item[key]
            }
        }
        item[config.param.ip] = tmpConfig
    } else if (config.type === 2 && config.param.location) {
        item[config.param.location] = tmpConfig
    } else if (config.type === -1) {
        item = tmpConfig
    } else {
        // Something went wrong
        console.log('error, config')
        return
    }

    // Filter subnewsfeed if there
    if (item.item && item.item.newsfeed && config.type !== -1) {
        for (const subItem of item.item.newsfeed) {
            const subIndex = state.fpConfig.reportConfig.findIndex((i) => i.item.uuid == subItem.uuid)
            if (subIndex > -1) {
                state.fpConfig.reportConfig.splice(subIndex, 1)
            }
        }
    }

    if (config.type === -1) {
        state.fpConfig.serverConfigChanges.push(item)
    } else {
        state.fpConfig.reportConfig.push(item)
    }
}
